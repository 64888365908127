import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import gsap, { TweenLite, Power0 } from 'gsap';

import {
  KeyboardService,
  MyKeyboardEvent,
} from '../../services/view/keyboard.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
declare var Inputmask: any;
import {
  ShortcutInput,
  ShortcutEventOutput,
  KeyboardShortcutsComponent,
} from 'ng-keyboard-shortcuts';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyboardComponent implements OnInit, AfterViewInit {
  constructor(
    private keyboardService: KeyboardService,
    private utils: UtilsService,
    private appConfig: AppConfigService
  ) {}
  // @Input() titre: string;
  titre: string;
  showHelper = true;
  shortcuts: ShortcutInput[] = [];

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('KeyboardComponent:ngOnInit');
    }
    if (this.utils.md().mobile() !== null) {
      this.showHelper = false;
    }
    this.keyboardService.emitter$.subscribe((event: any) =>
      this._eventDispatch(event)
    );
  }

  ngAfterViewInit(): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'KeyboardComponent:ngAfterViewInit [' +
          this.utils.parser.getOS().name +
          ']'
      );
    }

    if (this.utils.parser.getOS().name !== 'Android') {
      const im = new Inputmask({
        // tslint:disable-next-line: quotemark
        regex: this.appConfig.INPUT_MASK,
        placeholder: ' ',
      });
      im.mask(document.querySelector('#inputfieldtxt'));
    }

    $('#inputfieldtxt').on('focus', () => {
      if (this.appConfig.DEBUG) {
        console.log('KeyboardComponent:focus triggered');
      }
    });
    $('#inputfieldtxt').on('blur', () => {
      if (this.appConfig.DEBUG) {
        console.log('KeyboardComponent:blur triggered');
      }
    });

    this.shortcuts = [];
    this.shortcuts.push(
      {
        key: ['up up down down left right left right b a'],
        label: 'Sequences',
        description: 'Konami code!',
        command: (output: ShortcutEventOutput) => {
          if (this.appConfig.DEBUG) {
            console.log('Konami code!!!', output);
          }
          this.keyboardService.konami();
        },
      },
      {
        key: ['4 8 1 5 1 6 2 3 4 2'],
        label: 'Sequences',
        description: 'Cheat code!',
        command: (output: ShortcutEventOutput) => {
          if (this.appConfig.DEBUG) {
            console.log('Cheat code!!!', output);
          }
          this.keyboardService.cheatCode();
        },
      }
    );

    // this.keyboardService.focus();
  }

  onSubmit(): void {
    setTimeout(() => {
      $('#inputfieldtxt').trigger('blur');
      $('.field-wrap').removeClass('mobile');

      if (this.appConfig.DEBUG) {
        console.log('KeyboardComponent:onSubmit: ' + this.titre);
      }
      if (this.titre === undefined) {
        $('#inputfieldtxt').trigger('focus');
        if (this.utils.md().mobile() !== null) {
          $('.field-wrap').addClass('mobile');
        }
        return;
      }

      if (
        this.titre.toLowerCase() === 'ca' ||
        this.titre.toLowerCase() === 'ça'
      ) {
        this.titre = 'çachapitre1';
        console.log('KeyboardComponent:onSubmit: new title -> ' + this.titre);
      }

      if (this.titre.toLowerCase() === 'et') {
        this.titre = 'e.t.';
        console.log('KeyboardComponent:onSubmit: new title -> ' + this.titre);
      }

      if (this.titre.replace(/\s/g, '') === '') {
        if (this.appConfig.DEBUG) {
          console.log('KeyboardComponent:onSubmit : EMPTY FIELD');
        }
        $('#inputfieldtxt').trigger('focus');
        if (this.utils.md().mobile() !== null) {
          $('.field-wrap').addClass('mobile');
        }
        return;
      }

      //  INPUT_MASK = "^[a-zA-Z0-9àéèç\\-',&:!?\\s]+$";

      let filtered = this.titre.replace(/[^a-zA-Z0-9àéèç\-',&:!?\s]/g, '');
      if (this.appConfig.DEBUG) {
        console.log('KeyboardComponent:onSubmit : filtered -> ' + filtered);
      }

      // if (filtered === 'et') {
      //   filtered = 'e.t.';
      //   if (this.appConfig.DEBUG) {
      //     console.log('KeyboardComponent:onSubmit : unfiltered -> ' + filtered);
      //   }
      // }

      this.keyboardService.submit(encodeURI(filtered));
    }, 1);
  }

  onChange(event: any): void {
    setTimeout(() => {
      if (this.appConfig.DEBUG) {
        console.log(
          'KeyboardComponent:onChange: event: ' + JSON.stringify(event, null, 4)
        );
      }

      // if ($('#inputfieldtxt').val() === '') {
      //   $('#inputfieldtxt').val(event.key);
      // }
      // console.log(
      //   'KeyboardComponent:onChange: #inputfieldtxt: >' +
      //     $('#inputfieldtxt').val() +
      //     '<'
      // );
      $('.copycat').html($('#inputfieldtxt').val() as string);
      // const pourcent = 100 * ($('.copycat').width() / window.innerWidth);
      // console.log('KeyboardComponent:onChange %s %', pourcent);

      this._sizing(
        $('#inputfieldtxt'),
        $.fn.width,
        window.innerWidth - this.appConfig.INPUT_PADDING,
        this.appConfig.INPUT_MIN_FONTSIZE,
        this.appConfig.INPUT_MAX_FONTSIZE
      );

      const WfontSize = this._sizing(
        $('.copycat'),
        $.fn.width,
        window.innerWidth - this.appConfig.INPUT_PADDING,
        this.appConfig.INPUT_MIN_FONTSIZE,
        this.appConfig.INPUT_MAX_FONTSIZE
      );

      $('#inputfieldtxt').css('font-size', WfontSize);
      $('.copycat').css('font-size', WfontSize);

      this._centerInputField($('.copycat').width());
    }, 1);
  }

  show(): void {
    if (this.utils.md().mobile() !== null) {
      $('.field-wrap').addClass('mobile');
    }
  }

  _eventDispatch(event): void {
    switch (event.name) {
      case this.keyboardService.CENTER:
        this._centerInputField(event.key);
        break;
      case this.keyboardService.RESET:
        this.titre = '';
        break;
      case this.keyboardService.KEYPRESSED:
        this.show();
        break;
      default:
        break;
    }
  }

  _centerInputField(width): void {
    // console.log("KeyboardComponent:_centerInputField: " + width);
    return;

    // const inputWrap = $('> div:not(.keyboard)', '.field-wrap');
    const inputWrap = $('.field-wrap');
    gsap.killTweensOf(inputWrap);
    TweenLite.to(inputWrap, 0.2, {
      x: Math.round(width / -2),
      ease: Power0.easeOut,
      onComplete: () => {
        $('#inputfieldtxt').trigger('focus');
      },
    });
  }

  _sizing(ourText, func, max, minFontPixels, maxFontPixels): any {
    // console.log("KeyboardComponent:_sizing");

    while (minFontPixels < maxFontPixels - 1) {
      const fontSize = Math.floor((minFontPixels + maxFontPixels) / 2);
      ourText.css('font-size', fontSize);
      if (func.call(ourText) <= max) {
        minFontPixels = fontSize;
        if (func.call(ourText) === max) {
          break;
        }
      } else {
        maxFontPixels = fontSize;
      }
    }
    ourText.css('font-size', maxFontPixels);
    if (func.call(ourText) <= max) {
      minFontPixels = maxFontPixels;
    }
    return minFontPixels;
  }
}
