import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { RoughEase } from 'gsap/EasePack';
import gsap from 'gsap';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import * as $ from 'jquery';
import { RotateDeviceService } from 'src/app/services/view/rotate-device.service';
import * as screenfull from 'screenfull';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { RedbullTrackingService } from 'src/app/services/utilities/redbull-tracking.service';
import { LoadService } from 'src/app/services/game/load.service';
import { ProgressService } from 'src/app/services/game/progress.service';
import { SaveService } from 'src/app/services/game/save.service';
import { PlayerService } from 'src/app/services/user/player.service';
import {
  VideoPlayerEvent,
  VideoplayerService,
} from 'src/app/services/video/videoplayer.service';
import { Subscription } from 'rxjs';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';
import { ButtonService } from 'src/app/services/view/button.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private sounsService: SoundService,
    private $gaService: GoogleAnalyticsService,
    // private rbTracking: RedbullTrackingService,
    private rotateDeviceService: RotateDeviceService,
    private utils: UtilsService,
    private playerService: PlayerService,
    private loadService: LoadService,
    private progressService: ProgressService,
    private saveService: SaveService,
    private videoPlayerService: VideoplayerService,
    private cursorService: CursorService,
    private buttonService: ButtonService,

    private router: Router,
    private ngZone: NgZone
  ) {}

  title1 = 'Classement';
  title1Over = 'top 10';
  title2 = 'Reprendre ta partie';
  title2Over = 'Connect';
  title3 = 'Nouvelle partie';
  title3Over = 'Commencer';
  title4 = 'Continuer la partie';
  title4Over = 'Continuer';
  assetsUrl = this.appConfig.assetsUrl;
  videoSub: Subscription;
  buttonSubs: Subscription;
  isMobile = false;
  private onResizeBound: () => {};

  ngOnInit(): void {
    gsap.registerPlugin(DrawSVGPlugin, RoughEase);

    this.videoSub = this.videoPlayerService.emitter$.subscribe(
      (event: VideoPlayerEvent) => this.eventDispatch(event)
    );
    // this.onResizeBound = this._onCanvasResize.bind(this);
    // $(window).on('resize', this.onResizeBound);
    // this._onCanvasResize();

    this._initButtonsEvent();
    setTimeout(() => {
      this.$gaService.pageView('/home', 'Home Menu');
      this.cursorService.normal();
      this._showHome();
    }, 10);
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('HomeComponent:ngOnDestroy');
    }
    this.videoSub.unsubscribe();
    this.buttonSubs.unsubscribe();
    // $(window).off('resize', this.onResizeBound);
  }

  private _initButtonsEvent() {
    $('html,body,canvas').css('cursor', 'none');
    this.buttonSubs = this.buttonService.emitter$.subscribe((event) =>
      this.eventDispatch(event)
    );
  }

  eventDispatch(event: any): void {
    switch (event.name) {
      case this.buttonService.ROLLOVER:
        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.ROLLOVER)
        );
        break;
      case this.buttonService.ROLLOUT:
        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.ROLLOUT)
        );
        break;
      // case this.transitionService.DONE:
      //   if (event.to === 'Home') {
      //     if (this.appConfig.GA_STATS) {
      //       this.$gaService.pageView('/home', 'Home Menu');
      //     }
      //     this.cursorService.normal();
      //     this._showHome();
      //   }
      //   break;
      // case this.transitionService.START:
      //   if (event.to === 'Game') {
      //   }
      //   break;
      case this.videoPlayerService.HIDDEN:
        break;

      default:
        break;
    }
  }

  _showHome(): void {
    gsap.to('#home', {
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.soixante-6', {
      delay: 0.1,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });

    gsap.to('.goal', {
      delay: 0.2,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.btn1', {
      delay: 0.4,
      duration: 0.4,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.btn2', {
      delay: 0.6,
      duration: 0.4,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.btn3', {
      delay: 0.8,
      duration: 0.4,
      opacity: 1,
      ease: 'power3.out',
    });
  }

  none(): void {}

  ranking(): void {
    this.$gaService.event(
      'Show Ranking From Home',
      'Interactions',
      'Show Ranking From Home'
    );
    this._goto('/ranking');
  }

  login(): void {
    this.$gaService.event('Load Game', 'Interactions', 'Connect to Load Game');

    this._goto('/login');
  }
  startNewGame(): void {
    if (this.utils.md().mobile() !== null) {
      if (this.utils.md().os() !== 'iOS' && this.utils.md().os() !== 'iPadOS') {
        if (screenfull.isEnabled) {
          screenfull.toggle();
        }
      }
    }

    this.progressService.reset();
    this.playerService.gameid = '';
    this.loadService.reset();
    this.progressService.resetAnswers();
    this.saveService.reset();

    this.$gaService.event('New Game', 'Interactions', 'Star a new game');

    $('.footer').css('display', 'none');
    gsap.killTweensOf('#home');
    gsap.to('#home', {
      duration: 0.3,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        // if (this.utils.md().mobile() !== null) {
        //   this.rotateDeviceService
        //     .switchToHorizontal('pour jouer.')
        //     .then(() => {
        //       this._goto('/game');
        //     });
        // } else {
        this.videoPlayerService.hide();

        this._goto('/game', 0.8);
        setTimeout(() => {
          this.sounsService.stopIntro();
        }, 800);
        // }
      },
    });
  }

  _goto(page: string, delay = 0.3): void {
    gsap.killTweensOf('#home');
    gsap.to('#home', {
      duration: delay,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        this.ngZone.run(() =>
          this.router.navigate([page], {
            skipLocationChange: true,
          })
        );
      },
    });
  }

  _onCanvasResize() {
    if (this.appConfig.DEBUG) {
      console.log(
        'HomeComponent:_onCanvasResize scale : ' + this.utils.getUIScale()
      );
    }

    if (this.utils.md().mobile() !== null) {
      if (this.rotateDeviceService.getOrientation() === 'landscape') {
        this.rotateDeviceService.enable();
      } else {
        this.rotateDeviceService.disable();
      }
    }
    if (this.utils.getUIScale() > 1) {
      $('#home').css('transform', 'scale(' + this.utils.getUIScale() + ')');
    } else {
      $('#home').css('transform', 'unset');
    }
  }
}
