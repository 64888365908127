<div id="ranking" class="d-flex flex-column justify-content-center align-items-center h-100"
  (wheel)="onMouseWheel($event)">
  <div class="d-flex flex-row align-items-center main-title">
    <div>
      <img src="{{assetsUrl}}/assets/images/ranking/ranking.png" srcset="{{assetsUrl}}/assets/images/ranking/ranking@2x.png 2x,
                 {{assetsUrl}}/assets/images/ranking/ranking@3x.png 3x" class="ranking-title">
    </div>
  </div>
  <div class="d-flex flex-row align-items-center ranking-goal">
    TOP {{MAX}} Des meilleurs Cinéphiles
  </div>
  <div>
    <img src="{{assetsUrl}}/assets/images/ranking/liseret.svg" class="liseret">
  </div>
  <div class="flex-row align-items-center line currentPlayer"
    [ngClass]="{ show: showPlayer === true && playerPosition > 0 }">
    <div class="position">{{ playerPosition }}.</div>
    <div class="name">{{ playerName }}</div>
    <div class="score">{{ playerScore }}</div>
    <div class="popcorn">
      <svg xmlns="http://www.w3.org/2000/svg" width="29.791" height="27.821" viewBox="0 0 34 34">

        <path class="poppath"
          d="M-3090.914 2451.1a8.276 8.276 0 0 0-5.122 7.652 8.272 8.272 0 0 0 8.272 8.273 8.274 8.274 0 0 0 8.274-8.273q0-.309-.022-.612"
          transform="translate(3097.537 -2440.707)"
          style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px" />
        <path class="poppath"
          d="M-3086.5 2465.428a8.232 8.232 0 0 0 5.123 1.779 8.275 8.275 0 0 0 8.273-8.274 8.274 8.274 0 0 0-8.273-8.273 8.247 8.247 0 0 0-5.123 1.776"
          transform="translate(3101.394 -2440.886)"
          style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px" />
        <path class="poppath"
          d="M-3075.866 2453.653q.023-.3.022-.612a8.273 8.273 0 0 0-8.273-8.273 8.273 8.273 0 0 0-8.272 8.273c0 .208.007.415.022.619a8.279 8.279 0 0 0 5.121 7.042"
          transform="translate(3099.011 -2443.267)"
          style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px" />
      </svg>
    </div>
  </div>

  <div class="d-flex flex-row align-items-start line" *ngFor="let player of highScore; let i = index">
    <div class="position" [ngClass]="{ first: i === 0, second: i === 1, third: i === 2 }">
      {{ i + 1 }}.
    </div>
    <div class="name" [ngClass]="{ first: i === 0, second: i === 1, third: i === 2 }">
      {{ player.name }}
    </div>
    <div class="score" [ngClass]="{ first: i === 0, second: i === 1, third: i === 2 }">
      {{ player.scoreStr }}
    </div>
    <div class="popcorn" [ngClass]="{ first: i === 0, second: i === 1, third: i === 2 }">
      <svg xmlns="http://www.w3.org/2000/svg" width="29.791" height="27.821" viewBox="0 0 34 34">
        <path class="poppath"
          d="M-3090.914 2451.1a8.276 8.276 0 0 0-5.122 7.652 8.272 8.272 0 0 0 8.272 8.273 8.274 8.274 0 0 0 8.274-8.273q0-.309-.022-.612"
          transform="translate(3097.537 -2440.707)"
          style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px" />
        <path class="poppath"
          d="M-3086.5 2465.428a8.232 8.232 0 0 0 5.123 1.779 8.275 8.275 0 0 0 8.273-8.274 8.274 8.274 0 0 0-8.273-8.273 8.247 8.247 0 0 0-5.123 1.776"
          transform="translate(3101.394 -2440.886)"
          style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px" />
        <path class="poppath"
          d="M-3075.866 2453.653q.023-.3.022-.612a8.273 8.273 0 0 0-8.273-8.273 8.273 8.273 0 0 0-8.272 8.273c0 .208.007.415.022.619a8.279 8.279 0 0 0 5.121 7.042"
          transform="translate(3099.011 -2443.267)"
          style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px" />
      </svg>
    </div>
  </div>
  <div class="close-cross">
    <img src="{{assetsUrl}}/assets/images/x.png" srcset="{{assetsUrl}}/assets/images/x@2x.png 2x,
                 {{assetsUrl}}/assets/images/x@3x.png 3x" class="img-fluid">
  </div>
</div>
