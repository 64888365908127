<div id="comboContainer" class="flex-column justify-content-center align-items-center h-100">
  <div class="d-flex flex-row align-items-center">
    <div class="badges"><img class="bonus-badge" src="{{svg}}" /></div>
  </div>
  <div class="d-flex flex-row align-items-center">
    <div class="msg text-center" style="margin-top: -5px">
      <span class="label gold">quiz débloqué !</span>
    </div>
  </div>
  <div class="d-flex flex-row align-items-center">
    <div class="combo-points">+ {{ points }}</div>
  </div>
  <div class="bgCombo">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div>

<div id="bonusContainer" class="flex-column justify-content-center align-items-center h-100">
  <div class="d-flex flex-row align-items-center rbmoto">
    Bonus Time !
  </div>
  <div class="d-flex flex-row align-items-center">
    <img class="multiplier" src="{{assetsUrl}}/assets/images/game/bonus/bonus-popcorn-x{{ multiplier }}.png"
      data-pin-nopin="true" />
  </div>
  <div class="d-flex flex-row align-items-center rulez">
    <div *ngIf="multiplier == 2">
      <span class="golden">{{ duration }} secondes pour doubler tes&nbsp;points&nbsp;PopCorn !</span><br />
      Attention... la moindre erreur met fin au bonus.
    </div>
    <div *ngIf="multiplier == 3">
      <span class="golden">{{ duration }} secondes pour tripler tes&nbsp;points&nbsp;PopCorn !</span><br />
      Attention... la moindre erreur met fin au bonus.
    </div>
    <div *ngIf="multiplier == 4">
      <span class="golden">{{ duration }} secondes pour quadrupler tes&nbsp;points&nbsp;PopCorn !</span><br />
      Attention... la moindre erreur met fin au bonus.
    </div>
  </div>
  <div class="d-flex flex-row align-items-center">
    <simple-btn class="popcornTime-button" [buttonText]="title1" [buttonTextOver]="title1Over"
      (buttonClick)="popCornTime($event)" [isDisabled]="false" [isFocused]="false">
    </simple-btn>

  </div>
  <div class="bgCombo">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div>

<!--
  <div id="kcContainer" class="flex-column justify-content-center align-items-center h-100">
  <div class="d-flex flex-row align-items-center rbmoto">
    Red Bull donne des aiiiles !
  </div>
  <div class="d-flex flex-row align-items-center">
    <img src="{{assetsUrl}}/assets/images/game/bonus/god_yellow.png" class="img-fluid god top-layer"
      data-pin-nopin="true" />
    <img src="{{assetsUrl}}/assets/images/game/bonus/god_blue.png" class="img-fluid god middle-layer"
      data-pin-nopin="true" />
    <img src="{{assetsUrl}}/assets/images/game/bonus/god_red.png" class="img-fluid god bottom-layer"
      data-pin-nopin="true" />
  </div>
  <div class="d-flex flex-row align-items-center">
    <img class="mode" src="{{assetsUrl}}/assets/images/game/bonus/mode.png" data-pin-nopin="true" />
  </div>
  <div class="d-flex flex-row align-items-center rulez">
    <div>
      <span class="bold">{{ duration }} secondes sans craindre</span><br />
      la moindre erreur !
    </div>
  </div>
  <div class="d-flex flex-row align-items-center">
    <div class="round-button" (click)="godMode($event)">Go!</div>
  </div>
  <div class="bgCombo">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div>

<div id="cheatContainer" class="flex-column justify-content-center align-items-center h-100">
  <div class="d-flex flex-row align-items-center rbmoto">Red Bull donne des aiiiles !</div>
  <div class="d-flex flex-row align-items-center">
    <img src="{{assetsUrl}}/assets/images/game/bonus/cheatcode/cheat_yellow.png" class="img-fluid cheat top-layer"
      data-pin-nopin="true">
    <img src="{{assetsUrl}}/assets/images/game/bonus/cheatcode/cheat_blue.png" class="img-fluid cheat middle-layer"
      data-pin-nopin="true">
    <img src="{{assetsUrl}}/assets/images/game/bonus/cheatcode/cheat_red.png" class="img-fluid cheat bottom-layer"
      data-pin-nopin="true">
  </div>
  <div class="d-flex flex-row align-items-center">
    <img class="img-fluid code" src="{{assetsUrl}}/assets/images/game/bonus/cheatcode/code.png" data-pin-nopin="true">
  </div>
  <div class="d-flex flex-row align-items-center rulez">
    <div>
      Le PopCorn sourit aux audacieux !
    </div>
  </div>
  <div class="d-flex flex-row align-items-center gain">
    <div>
      + {{ cheatCodePoints }}
    </div>
  </div>
  <div class="bgCombo">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div> -->
