import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { KeyboardService } from 'src/app/services/view/keyboard.service';
import * as Inputmask from 'inputmask';
import * as $ from 'jquery';

@Component({
  selector: 'keyboard-mobile',
  templateUrl: './keyboard-mobile.component.html',
  styleUrls: ['./keyboard-mobile.component.scss'],
})
export class KeyboardMobileComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(
    private keyboardService: KeyboardService,
    private utils: UtilsService,
    private appConfig: AppConfigService
  ) {}
  // @Input() titre: string;
  titre: string;
  showHelper = false;
  keyboardSubs: Subscription;
  txtTranslatedFront;
  loading = false;

  ngOnInit() {
    if (this.appConfig.DEBUG) {
      console.log('KeyboardMobileComponent:ngOnInit');
    }
    this.keyboardService.init();
    // this.txtTranslatedFront = this.appConfig.translation;

    if (this.utils.md().mobile() !== null) {
      this.showHelper = false;
    }
    this.keyboardSubs = this.keyboardService.emitter$.subscribe((event: any) =>
      this._eventDispatch(event)
    );
  }

  ngAfterViewInit() {
    if (this.appConfig.DEBUG) {
      console.log('KeyboardMobileComponent:ngAfterViewInit');
    }

    // if (this.utils.md().os() === "iOS") {
    //   $(".field-wrap").css("height", "50vh");
    // }

    const im = new Inputmask({
      // tslint:disable-next-line: quotemark
      regex: this.appConfig.INPUT_MASK,
      placeholder: ' ',
    });
    // im.mask(document.querySelectorAll('input'));
    im.mask(document.querySelector('#inputfieldtxt'));

    if (this.appConfig.DEBUG) {
      $('#inputfieldtxt').on('focus', () => {
        console.log('KeyboardMobileComponent:focus triggered');
      });
      $('#inputfieldtxt').on('blur', () => {
        console.log('KeyboardMobileComponent:blur triggered');
      });
    }

    // this.keyboardService.focus();
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('KeyboardMobileComponent:ngOnDestroy');
    }
    this.keyboardSubs.unsubscribe();
  }
  onClick(event: any, titre: string) {
    // event.preventDefault();
    event.stopPropagation();
    this.onSubmit(titre);
    if (this.appConfig.DEBUG) {
      console.log('KeyboardMobileComponent:onClick: ' + this.titre);
    }
  }
  onSubmit(titre: string) {
    setTimeout(() => {
      this.loading = true;
      $('#inputfieldtxt').trigger('blur');
      this.titre = titre;
      if (this.appConfig.DEBUG) {
        console.log('KeyboardMobileComponent:onSubmit: ' + this.titre);
      }
      if (this.titre === undefined) {
        $('#inputfieldtxt').trigger('focus');
        this.loading = false;

        return;
      }

      if (
        this.titre.toLowerCase() === 'ca' ||
        this.titre.toLowerCase() === 'ça'
      ) {
        this.titre = 'çachapitre1';
        console.log('KeyboardComponent:onSubmit: new title -> ' + this.titre);
      }

      if (this.titre.toLowerCase() === 'et') {
        this.titre = 'e.t.';
        console.log('KeyboardComponent:onSubmit: new title -> ' + this.titre);
      }

      if (this.titre.replace(/\s/g, '') === '') {
        if (this.appConfig.DEBUG) {
          console.log('KeyboardMobileComponent:onSubmit : EMPTY FIELD');
        }
        $('#inputfieldtxt').trigger('focus');
        this.loading = false;

        return;
      }

      let filtered = this.titre.replace(/[^a-zA-Z0-9àéèç\-',&:!?\s]/g, '');
      if (this.appConfig.DEBUG) {
        console.log('KeyboardComponent:onSubmit : filtered -> ' + filtered);
      }

      this.keyboardService.submit(encodeURI(filtered));
    }, 1);
  }

  show() {
    if (this.utils.md().mobile() !== null) {
      $('.field-wrap').addClass('mobile');
    }
  }

  _eventDispatch(event) {
    switch (event.name) {
      case this.keyboardService.RESET:
        this.titre = '';
        this.loading = false;
        break;
      case this.keyboardService.KEYPRESSED:
        this.loading = false;
        this.show();
        break;
      case this.keyboardService.LOADING:
        this.loading = true;
        break;
      case this.keyboardService.IDLE:
        this.loading = false;
        break;
      default:
        break;
    }
  }
}
