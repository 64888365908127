<div id="home" class="d-flex flex-column justify-content-center align-items-center h-100">
  <div class="d-flex flex-row align-items-center mb-4">
    <div><img class="soixante-6" src="{{assetsUrl}}/assets/images/intro/50.png" data-pin-nopin="true" /></div>
  </div>
  <div class="d-flex flex-row align-items-end mb-4">
    <div class="goal">
      50 références de film<br>
      dissimulées dans une loge
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center" style="margin-bottom: 10vh;">
    <div class="mt-2 mb-2 btn1">
      <simple-btn [buttonText]="title1" [buttonTextOver]="title1Over" (buttonClick)="ranking()" [isDisabled]="false">
      </simple-btn>
    </div>
    <div class="mb-2 btn2">
      <simple-btn [buttonText]="title2" [buttonTextOver]="title2Over" (buttonClick)="login()" [isDisabled]="false">
      </simple-btn>
    </div>
    <div class="btn3">
      <simple-btn [buttonText]="title3" [buttonTextOver]="title3Over" (buttonClick)="startNewGame()"
        [isDisabled]="false" [isFocused]="true">
      </simple-btn>
    </div>
  </div>
</div>
