import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { GameEvent, GameService } from 'src/app/services/game/game.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { ToastrService } from 'ngx-toastr';
import { ScreenService } from 'src/app/services/view/screen.service';
import { JarvisService } from 'src/app/services/view/jarvis.service';
import { BadgeEvent, BadgeService } from 'src/app/services/view/badge.service';
import { PinsEvent, PinsService } from 'src/app/services/view/pins.service';
import {
  DashboardEvent,
  DashboardService,
} from 'src/app/services/view/dashboard.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import {
  KeyboardService,
  MyKeyboardEvent,
} from 'src/app/services/view/keyboard.service';
import {
  MouseControlsEvent,
  MouseControlsService,
} from 'src/app/services/view/mouse-controls.service';
import { PlayerService } from 'src/app/services/user/player.service';
import { Games, ServerService } from 'src/app/services/http/server.service';
import { SaveEvent, SaveService } from 'src/app/services/game/save.service';
import { Router } from '@angular/router';
import { ComboService } from 'src/app/services/game/combo.service';
import { LoadService } from 'src/app/services/game/load.service';
import { ProgressService } from 'src/app/services/game/progress.service';
import * as StateMachine from 'javascript-state-machine';
import { Answer, AnswersType } from 'src/app/model/answers-type';
import { Game } from 'src/app/model/game';
import { Show } from 'src/app/model/show';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';
import * as $ from 'jquery';
import gsap from 'gsap';

import { TimerService } from 'src/app/services/utilities/timer.service';
import { TutosService } from 'src/app/services/game/tutos.service';
import { isWebGLSupported } from 'webgl-detector';
import { TouchControlsService } from 'src/app/services/view/touch-controls.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { TouchControlsEvent } from '../../services/view/touch-controls.service';
import { RotateDeviceService } from 'src/app/services/view/rotate-device.service';
// import { routeAnimations } from 'src/app/animations/route.animations';
import { GameStatusService } from 'src/app/services/game/game-status.service';
import { VideoplayerService } from 'src/app/services/video/videoplayer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
  // animations: [routeAnimations],
})
export class GameComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private gameService: GameService,
    private utils: UtilsService,
    private toastr: ToastrService,
    private screenService: ScreenService,
    private cursorService: CursorService,
    private jarvisService: JarvisService,
    private badgeService: BadgeService,
    private pinsService: PinsService,
    private dashboardService: DashboardService,
    private soundService: SoundService,
    private keyboardService: KeyboardService,
    private mouseControlsService: MouseControlsService,
    private touchControlsService: TouchControlsService,
    private playerService: PlayerService,
    private serverService: ServerService,
    private saveService: SaveService,
    private comboService: ComboService,
    private progressService: ProgressService,
    private timerService: TimerService,
    private loadService: LoadService,
    private tutosService: TutosService,
    private $gaService: GoogleAnalyticsService,
    // private rbTracking: RedbullTrackingService,
    private rotateDeviceService: RotateDeviceService,
    private gameStatusService: GameStatusService,
    private videoPlayerService: VideoplayerService,

    private ngZone: NgZone,
    private router: Router
  ) {}

  fsm: StateMachine;
  game: Game;
  answersType = new AnswersType();
  isMobile = false;
  gameStatus: string;
  autoShowTutoRadar = true;
  lookupTitre = '';

  clickInputfieldBound = () => {};

  keyboardServiceSub: Subscription;
  mouseControlsServiceSub: Subscription;
  badgeServiceSub: Subscription;
  pinsServiceSub: Subscription;
  saveServiceSub: Subscription;
  gameServiceSub: Subscription;
  touchControlsServiceSub: Subscription;
  dashboardServiceSub: Subscription;

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent ngOnInit ' + this.utils.md().mobile());
    }
    const browser = this.utils.parser.getBrowser().name;

    if (this.utils.md().mobile() !== null) {
      this.isMobile = true;
      $('.wrapper').css('overflow-y', 'unset');
    } else {
      $('.wrapper').css('overflow-y', 'hidden');

      if (!isWebGLSupported()) {
        console.error('WebGL not supported');
        // webGL is not supported
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
    if (browser === 'Edge') {
      // this.isMobile = true;
    }
    this.fsm = new StateMachine({
      init: 'initialize',
      transitions: [
        {
          name: 'play',
          from: [
            'initialize',
            'typing',
            'typehere',
            'goodanswer',
            'wronganswer',
            'lookuptvshow',
            'pins',
            'dashboard',
            'pausemode',
            'systemerror',
            'tutos',
            'invincible',
          ],
          to: 'playing',
        },
        { name: 'end', from: ['goodanswer', 'wronganswer'], to: 'playing' },
        { name: 'type', from: ['playing', 'typehere'], to: 'typing' },
        { name: 'lookup', from: 'typing', to: 'lookuptvshow' },
        { name: 'good', from: 'lookuptvshow', to: 'goodanswer' },
        { name: 'wrong', from: 'lookuptvshow', to: 'wronganswer' },
        { name: 'error', from: 'lookuptvshow', to: 'systemerror' },
        { name: 'showPins', from: 'playing', to: 'pins' },
        { name: 'pause', from: 'playing', to: 'pausemode' },
        { name: 'checkTuto', from: 'goodanswer', to: 'tutos' },
        { name: 'checkTuto', from: 'wronganswer', to: 'tutos' },
        { name: 'checkCombo', from: 'tutos', to: 'combo' },
        {
          name: 'showDashboard',
          from: 'playing',
          to: 'dashboard',
        },
      ],
      methods: {
        onInitialize: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onInitialize');
          }
          this.videoPlayerService.destroy();
          this.screenService.emitter$.subscribe((event: any) =>
            this.eventDispatch(event)
          );
          this.jarvisService.emitter$.subscribe((event: any) =>
            this.eventDispatch(event)
          );
          if (this.appConfig.GA_STATS) {
            this.$gaService.pageView('/game', 'Game Time !');
          }
        },
        onPlay: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onPlay');
          }
          this.enableGame();
          // WAIT FOR USER INPUT
          this.keyboardService.focus();
          this.enableHUD();
        },
        onEnd: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onEnd');
          }
          this.gotoEnd();
        },
        onEnterTyping: () => {
          return new Promise((resolve, reject) => {
            if (this.appConfig.DEBUG) {
              console.log('StateMachine: onEnterTyping');
            }
            this.mouseControlsService.disable();
            this.screenService.blur();
            if (this.utils.md().mobile() !== null) {
              this.jarvisService.hideui();
              $('.field-wrap').addClass('mobile');
            }

            this.showInputField(resolve, reject, false);
          });
        },
        onLeaveTyping: (lifecycle) => {
          return new Promise((resolve) => {
            if (this.appConfig.DEBUG) {
              console.log('StateMachine: onLeaveTyping -> ' + lifecycle.to);
            }

            if (lifecycle.to !== 'lookuptvshow') {
              if (this.appConfig.DEBUG) {
                console.log('Call hideInputField from State Machine');
              }
              if (this.utils.md().mobile() !== null) {
                this.jarvisService.showui();
              }
              this.hideInputField(resolve);
              this.screenService.unblur();
            } else {
              return resolve(true);
            }
          });
        },
        // onGodMode: (lifecycle) => {
        //   if (this.appConfig.DEBUG) {
        //     console.log('StateMachine: onGodMode')
        //   }
        //   if (!this.comboService.isActive) {
        //     // if (!this.progressService.isGodModeUsed) {
        //     if (this.appConfig.DEBUG) {
        //       console.log(
        //         'GameComponent:kazuhisa -> StateMachine state: ' +
        //           this.fsm.state
        //       )
        //     }
        //     // this.keyboardService.escape();
        //     // setTimeout(() => {
        //     this.comboService.godMode()
        //     // }, 1000);
        //   }
        // },
        onLookup: (lifecycle, titre: string) => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onLookup');
          }
          this.lookupTitre = decodeURI(titre);
          if (this.appConfig.GA_STATS) {
            this.$gaService.event('Lookup Artists', 'Events', this.lookupTitre);
          }

          this.lookupShow(titre);
        },
        onGood: (lifecycle, show: Show) => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onGood');
          }
          if (this.appConfig.GA_STATS) {
            this.$gaService.event(
              'Good Artist',
              'Events',
              show.title + ' (' + this.lookupTitre + ')'
            );
          }

          // return new Promise(resolve => {
          this.screenService.unblur();
          this.hideInputField(undefined);
          this.lookupShowCallback(show);
          //   resolve();
          // });
        },
        onWrong: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onWrong');
          }
          if (this.appConfig.GA_STATS) {
            this.$gaService.event('Wrong Artist', 'Events', this.lookupTitre);
          }
          if (this.utils.md().mobile() !== null) {
            this.jarvisService.showui();
          }
          // return new Promise(resolve => {
          this.screenService.unblur();
          this.hideInputField(undefined);
          this.showError();
          //   resolve();
          // });
        },
        onError: (lifecycle, err) => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onError');
          }
          this.screenService.unblur();
          this.hideInputField(undefined);
          this.systemError(err);
        },
        onCheckTuto: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onCheckTuto');
          }
          return new Promise((resolve) => {
            this.checkTutos();
            resolve(0);
          });
        },
        onCheckCombo: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onCheckCombo');
          }
          return new Promise((resolve) => {
            this.checkCombo();
            resolve(0);
          });
        },
        onEnterPins: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onEnterPins');
          }
          this.mouseControlsService.deZoom();
          this.mouseControlsService.disableClick();
          this.mouseControlsService.disableZoom();
          this.keyboardService.disable();
          this.jarvisService.hideui();
        },
        onLeavePins: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onLeavePins');
          }
          this.mouseControlsService.enableClick();
          this.mouseControlsService.enableZoom();
          this.keyboardService.enable();
          this.jarvisService.showui();
        },
        onEnterDashboard: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onEnterDashboard');
          }
          this.screenService.blur();
          this.keyboardService.disable();
          this.mouseControlsService.disable();
          this.jarvisService.hideui();
        },
        onLeaveDashboard: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onLeaveDashboard');
          }
          this.screenService.unblur();
          this.keyboardService.enable();
          this.mouseControlsService.enable();
          this.jarvisService.showui();
        },
        onEnterPause: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onEnterPause');
          }
          this.screenService.blur();
          this.keyboardService.disable();
          this.mouseControlsService.disable();
          // this.jarvisService.hideui();
        },
        onLeavePause: () => {
          if (this.appConfig.DEBUG) {
            console.log('StateMachine: onLeavePause');
          }
          this.screenService.unblur();
          this.keyboardService.enable();
          this.mouseControlsService.enable();
          // this.jarvisService.showui();
        },
      },
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:ngOnDestroy');
    }

    this.keyboardServiceSub.unsubscribe();
    this.mouseControlsServiceSub.unsubscribe();
    this.badgeServiceSub.unsubscribe();
    this.pinsServiceSub.unsubscribe();
    this.saveServiceSub.unsubscribe();
    this.gameServiceSub.unsubscribe();
    this.touchControlsServiceSub.unsubscribe();
    this.dashboardServiceSub.unsubscribe();
  }

  initComponent(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:initComponent');
    }

    this.game = new Game();

    this.initPlayer();
    this.initServices();
    this.initListeners();
    this.initJarvis();
  }

  initServices(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:initServices');
    }
    this.keyboardService.init();
    this.comboService.init();
    // this.progressService.init();
  }

  initListeners(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:initServices');
    }

    this.keyboardServiceSub = this.keyboardService.emitter$.subscribe(
      (event: MyKeyboardEvent) => this.eventDispatch(event)
    );

    this.mouseControlsServiceSub = this.mouseControlsService.emitter$.subscribe(
      (event: MouseControlsEvent) => this.eventDispatch(event)
    );
    this.badgeServiceSub = this.badgeService.emitter$.subscribe(
      (event: BadgeEvent) => this.eventDispatch(event)
    );
    this.pinsServiceSub = this.pinsService.emitter$.subscribe(
      (event: PinsEvent) => this.eventDispatch(event)
    );

    this.saveServiceSub = this.saveService.emitter$.subscribe(
      (event: SaveEvent) => this.eventDispatch(event)
    );
    this.gameServiceSub = this.gameService.emitter$.subscribe(
      (event: GameEvent) => this.eventDispatch(event)
    );
    this.touchControlsServiceSub = this.touchControlsService.emitter$.subscribe(
      (event: TouchControlsEvent) => this.eventDispatch(event)
    );

    this.dashboardServiceSub = this.dashboardService.emitter$.subscribe(
      (event: DashboardEvent) => this.eventDispatch(event)
    );

    this.clickInputfieldBound = this.onClickInputField.bind(this);
  }

  initPlayer(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:initPlayer');
    }

    if (this.playerService.isConnected) {
      if (this.appConfig.DEBUG) {
        console.log(
          'GameComponent:initPlayer user %s connected',
          this.playerService.name
        );
      }
    }
  }

  initJarvis(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:initJarvis');
    }
    this.jarvisService.boot();
  }

  eventDispatch(event: any): void {
    switch (event.name) {
      case this.screenService.GAMEVIEWLOADED:
        this.initComponent();
        break;
      case this.screenService.GAMEVIEWREADY:
        // start game
        // window.parent.postMessage('hide-footer', '*');
        $('.footer').css('display', 'none');

        this.jarvisService.buildUI();
        break;
      case this.screenService.GAMEVIEWUNLOADED:
        // stop game
        this.cursorService.normal();
        this.jarvisService.shutdown(this.gameStatus);
        break;
      case this.jarvisService.BOOTSUCCESS:
        if (this.appConfig.GA_STATS) {
          this.$gaService.event('UI Loaded', 'Events', 'Lets Go');
        }
        this.startGame();
        this.playTracks();
        break;
      case this.gameService.PAUSE:
        // this.$gaService.event('PauseGame', 'Events', 'Pause mode')
        this.pauseGame((event as GameEvent).bonusMode);
        break;
      case this.gameService.RESUME:
        // this.$gaService.event('ResumeGame', 'Events', 'resume mode')
        this.resumeGame();
        break;
      case this.jarvisService.SHUTDOWNSUCESS:
        if (this.fsm.can('end')) {
          if (this.appConfig.GA_STATS) {
            this.$gaService.event('UI Closed', 'Events', 'Oups');
          }
          this.fsm.end();
        }

        break;
      case this.badgeService.SHOWBADGESUCESS:
        // this.showFocus((event as BadgeEvent).show);
        // this.mouseControlsService.deZoom();
        this.pinsService.showFocus((event as BadgeEvent).show);
        break;
      case this.pinsService.SHOWFOCUSSUCESS:
        if (this.comboService.active) {
          this.timerService.resumeChrono();
        }
        if (this.utils.md().mobile() !== null) {
          this.jarvisService.showui();
        }
        this.checkCombo();
        break;
      case this.saveService.SHOW:
      case this.dashboardService.SHOW:
        if (this.fsm.can('showDashboard')) {
          if (this.appConfig.GA_STATS) {
            this.$gaService.event(
              'Dashboard Mode',
              'Interactions',
              'Open the dashboard'
            );
          }
          this.fsm.showDashboard();
        }
        break;
      case this.pinsService.SHOWPINS:
        if (this.fsm.can('showPins')) {
          if (this.appConfig.GA_STATS) {
            this.$gaService.event('Radar Mode', 'Interactions', 'Show Pins');
          }
          this.fsm.showPins();
        }
        break;
      case this.mouseControlsService.MOUSEUP:
      case this.touchControlsService.TOUCHUP:
        // if (this.fsm.is('play')) {
        this.keyboardService.focus();
        // }
        break;
      case this.mouseControlsService.CLICK:
      case this.touchControlsService.TOUCH:
      case this.keyboardService.KEYPRESSED:
        if (this.fsm.can('type')) {
          this.fsm.type();
        }
        break;
      case this.jarvisService.UIREADY:
      case this.pinsService.HIDEPINSSUCESS:
      case this.saveService.HIDE:
      case this.dashboardService.HIDE:
      case this.keyboardService.ESC:
        if (this.fsm.can('play')) {
          this.fsm.play();
        }
        break;
      case this.keyboardService.SUBMIT:
        this.submit(event.titre);
        break;
      // case this.keyboardService.KONAMI:
      //   this.kazuhisa()
      //   break
      // case this.keyboardService.CHEATCODE:
      //   this.cheatCode()
      //   break
      case this.gameService.GODMODE:
        if (this.fsm.can('play')) {
          this.fsm.play();
        }
        break;

      default:
        break;
    }
  }

  startGame(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:startGame');
    }

    if (this.playerService.hasGame()) {
      // load game
      this.loadGame().then(() => {
        this.playerService.session = true;
        this.gameService.startGame();
      });
    } else {
      // transition to game
      // lance l'anim du mask de la composition => EVENT GAMEVIEWREADY en fin d'anim
      this.playerService.session = true;
      this.gameService.startGame();
    }
    if (this.fsm.can('play')) {
      this.fsm.play();
    }
  }

  pauseGame(bonusMode): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:pauseGame');
    }
    if (!bonusMode) {
      $('#click-to-resume').css('display', 'flex');
      gsap.to('#click-to-resume', {
        duration: 0.6,
        opacity: 1,
        ease: 'quad.out',
        onComplete: () => {
          this.cursorService.cross();
        },
      });
    }
    // this.keyboardService.escape();
    this.hideInputField();
    this.screenService.blur();
    this.keyboardService.disable();
    this.mouseControlsService.disable();
    this.soundService.dimSound();
  }

  resumeGame(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:resumeGame');
    }
    // window.parent.postMessage('hide-footer', '*');
    $('.footer').css('display', 'none');
    this.cursorService.normal();

    this.screenService.unblur();
    this.keyboardService.enable();
    this.mouseControlsService.enable();
    this.soundService.undimSound();
    if (this.fsm.can('play')) {
      this.fsm.play();
    }
  }

  loadGame(): any {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:loadGame');
    }

    const promise = new Promise((resolve) => {
      let game: Games;

      game = this.loadService.getLoadedGame();

      if (game !== undefined) {
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < game.answers.length; index++) {
          const answer: Answer = game.answers[index];

          // Gestion de l'historique des refs et init des pins
          if (
            answer.type === this.answersType.ARTIST &&
            answer.is_found === 1
          ) {
            this.game.showFounded.push(answer.badge_id);
            const show: Show = new Show();
            show.badge_id = answer.badge_id;
            show.x = answer.x;
            show.y = answer.y;
            show.flag = answer.flag;
            show.hash = answer.hash;
            show.title = answer.title;
            show.type = answer.show_type;

            // this.mouseControlsService.getFocusPosition(show).then(() => {
            this.pinsService.createPin(show);
            // });
          }
        }

        this.progressService.cleanLoadedAnswers();
        this.autoShowTutoRadar = false;
        this.tutosService.setShown(this.tutosService.TUTO_ZOOM);
        this.tutosService.setShown(this.tutosService.TUTO_ERROR);
      }

      resolve(true);
    });

    return promise;
  }

  stopGame(gameStatus: string): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:stopGame ' + gameStatus);
    }
    this.gameStatus = gameStatus;

    this.disableHUD();
    this.videoPlayerService.init();

    if (gameStatus === this.gameService.MISSION_FAILED) {
      setTimeout(() => {
        this.jarvisService.hideui();
        this.screenService.stop();
        this.mouseControlsService.disable();

        if (this.appConfig.GA_STATS) {
          this.$gaService.event(
            'Mission Failed!',
            'Events',
            'Fin de la partie'
          );
        }
        this.gameStatusService.defeat().then(() => {
          this.gameService.stopGame();
        });
      }, 1000);
    } else {
      setTimeout(() => {
        this.jarvisService.hideui();
        this.screenService.stop();
        this.mouseControlsService.disable();
        if (this.appConfig.GA_STATS) {
          this.$gaService.event(
            'Mission accomplished!',
            'Events',
            'Fin de la partie'
          );
        }
        this.gameStatusService.win().then(() => {
          this.gameService.stopGame();
        });
      }, 1000);
    }
  }

  shutdown(): void {
    // console.log('GameComponent:shutdown');
  }
  gotoEnd(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:gotoEnd');
    }
    this.stopTracks();
    this.playerService.session = false;
    this.soundService.intro();

    if (this.isMobile) {
      if (this.appConfig.DEBUG) {
        console.log('GameComponent:gotoEnd mobile');
      }

      this.rotateDeviceService
        .switchToVertical('pour voir ton profil')
        .then(() => {
          this.ngZone.run(() =>
            this.router.navigate(['/end'], {
              skipLocationChange: true,
            })
          );
        });
    } else {
      this.mouseControlsService.stop();

      this.ngZone.run(() =>
        this.router.navigate(['/end'], {
          skipLocationChange: true,
        })
      );
    }
  }

  disableHUD(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:disableHUD');
    }

    this.keyboardService.disable();
  }

  enableHUD(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:enableHUD');
    }
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.NORMAL));
    this.keyboardService.enable();
  }

  disableGame(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:disableGame');
    }
    this.keyboardService.disable();
    this.screenService.disable();
    this.jarvisService.disable();
  }

  enableGame(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:enableGame');
    }
    this.keyboardService.enable();
    this.screenService.enable();
    this.jarvisService.enable();
  }

  showInputField(resolve, reject, tutoMode): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:showInputField');
    }
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.CLOSE));

    let tweenDuration = 0.2;
    let tweenDelay = 3;

    if (tutoMode) {
      this.game.showTypeHereTimer = setTimeout(() => {
        this.fsm.play();
      }, 10000);
      tweenDuration = 0.6;
      tweenDelay = 10;
      $('.smallinfo').css('opacity', 1);
    } else {
      this.resetTimers();
      $('.smallinfo').css('opacity', 0);
    }

    if (this.keyboardService.isVisible()) {
      reject('input already visible');
      return;
    }

    // this.screenService.emitEvent(new ScreenEvent(this.screenService.BLUR));

    // this.screenService.disable();

    this.keyboardService
      .show(tweenDuration, tweenDelay, this.clickInputfieldBound)
      .then(() => {
        resolve(true);
      });
  }

  hideInputField(resolve?): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:hideInputField');
    }
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.NORMAL));
    // this.screenService.emitEvent(new ScreenEvent(this.screenService.UNBLUR));

    this.keyboardService.hide().then(() => {
      this.screenService.enable();
      if (resolve !== undefined) {
        resolve(true);
      }
    });
  }

  onClickInputField(event): void {
    if (event) {
      if (this.appConfig.DEBUG) {
        console.log('GameComponent:onClickInputField ' + event.target.id);
      }
      if (event.target.id !== 'overlay_layer') {
        if (this.appConfig.DEBUG) {
          console.log('GameComponent:onClickInputField return');
        }
        return;
      }
    }
    if (this.fsm.can('play')) {
      this.resetTimers();
      this.fsm.play();
    }
  }

  onClickInputFieldMobile(event) {
    if (event) {
      if (this.appConfig.DEBUG) {
        console.log(
          'GameComponent:onClickInputFieldMobile target ID: ' + event.target.id
        );
      }
      if (event.target.id !== 'overlay_layer') {
        if (this.appConfig.DEBUG) {
          console.log('GameComponent:onClickInputFieldMobile return');
        }
        return;
      }
    }
    if (this.fsm.can('play')) {
      this.resetTimers();
      this.fsm.play();
    }
  }

  submit(titre: string): void {
    let timer;

    timer = setInterval(() => {
      if (this.appConfig.DEBUG) {
        console.log('GameComponent::submit trial ' + titre);
      }
      if (this.fsm.can('lookup')) {
        clearInterval(timer);
        if (this.appConfig.DEBUG) {
          console.log('GameComponent::submit ' + titre);
        }
        this.fsm.lookup(titre);
      }
    }, 150);
  }

  resetTimers(): void {
    if (this.game.showTypeHereTimer !== null) {
      clearTimeout(this.game.showTypeHereTimer);
      this.game.showTypeHereTimer = null;
      this.game.showTypeHereModal = true;
    }
    if (this.game.showPinsTimer !== null) {
      clearTimeout(this.game.showPinsTimer);
      this.game.showPinsTimer = null;
    }
  }

  lookupShow(titre: string): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.LOADING));
    if (this.comboService.active) {
      this.timerService.pauseChrono();
    }
    this.serverService.lookupShow(titre).subscribe(
      (show: Show) => {
        if (this.appConfig.DEBUG) {
          console.log('GameComponent:lookupShow found ' + show.badge_id);
        }
        this.fsm.good(show); // .then(this.fsm.play.bind(this.fsm));

        // this.toastr.success('GOOD');
      },
      (err: any) => {
        if (this.appConfig.DEBUG) {
          console.log(
            'GameComponent:lookupShow 404 !' + JSON.stringify(err, null, 4)
          );
        }

        if (err.status === 404) {
          this.fsm.wrong();
          // this.toastr.error('WRONG');
        } else {
          // ERREUR INCONNUE
          this.fsm.error(err);
        }
      }
    );
  }

  lookupShowCallback(show: Show): void {
    if (this.game.showFounded.indexOf(show.badge_id) === -1) {
      if (this.appConfig.DEBUG) {
        console.log('lookupShowCallback: ' + show.title);
      }

      // SHOW BADGE
      this.badgeService.showBadge(show);
      // if (this.comboService.active) {
      //   this.timerService.pauseChrono();
      // }
      this.game.showFounded.push(show.badge_id);
      this.disableGame();
      this.progressService.saveAnswer(
        this.answersType.ARTIST,
        show.hash,
        1,
        '',
        0,
        this.comboService.multiplier,
        show.type
      );

      if (show.combo_id !== null) {
        if (show.combo_id !== 'cheatcode') {
          this.comboService.addCombo(show);
        }
      }
      this.progressService.computeScore();

      this.jarvisService.updateScore(this.progressService.score);
    } else {
      // ALREADY FOUND
      if (this.appConfig.DEBUG) {
        console.log(
          'GameComponent:lookupShowCallback ' + show.title + 'ALREADY FOUND '
        );
      }
      // this.toastr.info('ALREADY FOUND');
      this.hideInputField();
      this.tutosService.showTuto(this.tutosService.TUTO_RADAR).then(() => {
        this.autoShowTutoRadar = false;
        //  setTimeout(() => {
        this.backToGame();
        //  }, 1000);
      });
    }
  }

  showError(): void {
    this.disableGame();
    this.cursorService.loading();

    if (!this.progressService.onGodMode) {
      // this.comboService.stopPopCornTime(); // reset du multiplier courant
      this.timerService.stopChrono();
      this.progressService.saveAnswer(this.answersType.ARTIST); // sauvegarde de l'erreur
    }

    this.progressService.computeScore();

    const nbError = this.progressService.getProgress(
      this.progressService.ERROR
    );
    // SHOW ERROR
    this.screenService.error(nbError);

    if (this.appConfig.DEBUG) {
      console.log('GameComponent:showError ' + nbError);
    }

    // if (nbError < 3) {
    //   // this.soundService.error();
    // }
    // UPDATE JARVIS SCORE VIEW
    this.jarvisService.updateScore(this.progressService.score);
    this.jarvisService.updateError();

    // MAX ERRORS ?
    if (nbError >= this.appConfig.MAX_ERRORS) {
      // FIN GAME
      if (this.appConfig.DEBUG) {
        console.log('GameComponent:errorShow MAX ERRORS REACHED!!');
      }
      this.stopTracks();
      this.cursorService.loading();
      this.stopGame(this.gameService.MISSION_FAILED);
    } else {
      // this.soundService.error(nbError);
      this.jarvisService.error().then(() => {
        this.cursorService.normal();
        this.backToGame();
      });
    }
  }

  systemError(err): void {
    this.toastr.error(
      '[42] Un problème à été détécté, merci de recommencer. Error code: ' +
        err.status +
        ' (' +
        err.statusText +
        ')'
    );
    setTimeout(() => {
      // this.toastr.error(
      //   'Error code: ' + err.status + ' (' + err.statusText + ')'
      // );
      // setTimeout(() => {
      this.backToGame();
    }, 500);
  }

  showFocus(show: Show): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:showFocus: ' + show.badge_id);
    }
    // this.disableGame();
    this.pinsService.showFocus(show);
    // this.parseBonus();
  }
  checkCombo(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:checkCombo -> ' + this.fsm.state);
    }

    this.comboService.checkCombos().then(() => {
      this.jarvisService.updateNotif();
      // this.comboService.checkPopCornTime().then(() => {
      this.isGameOver();
      // })
    });
  }

  checkTutos(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameComponent:checkTutos -> ' + this.fsm.state);
    }

    this.tutosService.checkTuto().then(() => {
      this.checkCombo();
    });
  }

  isGameOver(): void {
    const nbRefs = this.progressService.getProgress(this.progressService.REFS);

    if (this.appConfig.DEBUG) {
      console.log('GameComponent:isGameOver nbRefs: ' + nbRefs);
    }

    if (nbRefs < this.appConfig.MAX_REFS) {
      if (!this.comboService.isActive) {
        if (
          nbRefs === this.appConfig.TUTO_RADAR_STEP &&
          this.autoShowTutoRadar
        ) {
          this.tutosService.showTuto(this.tutosService.TUTO_RADAR).then(() => {
            this.autoShowTutoRadar = false;
            this.backToGame();
          });
          // } else if (
          //   nbRefs % this.appConfig.TUTO_BONUS_STEP === 0 &&
          //   !this.tutosService.shown(this.tutosService.TUTO_QUIZ)
          // ) {
          //   if (this.utils.md().mobile() !== null) {
          //     this.backToGame();
          //   } else {
          //     this.tutosService.showTuto(this.tutosService.TUTO_QUIZ).then(() => {
          //       this.backToGame();
          //     });
          //   }
        } else if (
          nbRefs === this.appConfig.TUTO_ZOOM_STEP &&
          !this.tutosService.shown(this.tutosService.TUTO_ZOOM)
        ) {
          if (this.utils.md().mobile() !== null) {
            this.backToGame();
          } else {
            this.tutosService.showTuto(this.tutosService.TUTO_ZOOM).then(() => {
              this.backToGame();
            });
          }
        } else if (nbRefs % this.appConfig.SAVE_STEP === 0) {
          this.tutosService.showTuto(this.tutosService.TUTO_SAVE).then(() => {
            this.backToGame();
          });
        } else {
          this.backToGame();
        }
      } else {
        this.backToGame();
      }
    } else {
      // FIN  GAME
      this.cursorService.loading();
      if (this.playerService.isConnected) {
        this.saveService.saveGameAuthentified(true, 1);
      }
      this.timerService.stopChrono();
      this.stopGame(this.gameService.MISSION_COMPLETE);
    }
  }

  backToGame(): void {
    // REPRISE DU JEU
    if (this.fsm.can('play')) {
      this.enableGame();
      this.keyboardService.clear();
      this.fsm.play();
    }
  }

  playTracks(volume = this.appConfig.TRACKVOLUME): void {
    setTimeout(() => {
      this.soundService.playTracks(volume);
    }, 420);
  }

  stopTracks(): void {
    this.soundService.stopTracks();
  }

  // kazuhisa(): void {
  //   let timer
  //   if (!this.comboService.isActive && !this.progressService.isGodModeUsed) {
  //     timer = setInterval(() => {
  //       if (this.fsm.can('godMode') && !this.progressService.isGodModeUsed) {
  //         clearInterval(timer)
  //         this.fsm.godMode()
  //       }
  //     }, 150)
  //   }
  // }

  // cheatCode(): void {
  //   if (!this.progressService.cheatCodeUsed) {
  //     this.comboService.cheatCode()
  //   }
  // }
}
