<div id="tutosContainer" class="flex-column justify-content-center align-items-center">
  <div id="tuto_3">
    <img *ngIf="mobileMode == false" class="tuto" src="{{ assetsUrl }}/assets/images/game/tutos/desktop/tuto_3.png"
      data-pin-nopin="true" />
    <img *ngIf="mobileMode == true" class="tuto" src="{{ assetsUrl }}/assets/images/game/tutos/mobile/tuto_3.png"
      data-pin-nopin="true" />
  </div>
  <div id="tuto_4">
    <img *ngIf="mobileMode == false" class="tuto" src="{{ assetsUrl }}/assets/images/game/tutos/desktop/tuto_4.png"
      data-pin-nopin="true" />
    <img *ngIf="mobileMode == true" class="tuto" src="{{ assetsUrl }}/assets/images/game/tutos/mobile/tuto_4.png"
      data-pin-nopin="true" />
  </div>
  <div id="tuto_5">
    <img *ngIf="mobileMode == false" class="tuto" src="{{ assetsUrl }}/assets/images/game/tutos/desktop/tuto_5.png"
      data-pin-nopin="true" />
    <img *ngIf="mobileMode == true" class="tuto" src="{{ assetsUrl }}/assets/images/game/tutos/mobile/tuto_5.png"
      data-pin-nopin="true" />
  </div>
  <div id="tuto_6" class="d-flex justify-content-center align-items-end">
    <img class="tuto" src="{{ assetsUrl }}/assets/images/game/tutos/desktop/tuto_6.png" data-pin-nopin="true" />
  </div>
  <div class="bgTuto">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div>
